html {
  scroll-behavior: smooth;
}

/* Header background color override */
.ant-layout-header {
    background: #ffffff;
}

.map-container {
  height: 85vh;
}

#marker {
  background-image: url('./assets/images/logo.webp');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
    
.mapboxgl-popup {
  max-width: 200px;
}