  .tshirt {
    position: relative;
    margin: auto;
  }

  .img-label {
    color: #fdc7d7;
    position: absolute;
    left: 48.5%;
    top: 8.5%;
    font-weight: bold;
  }

  .droparea {
    position: absolute;
    top: 20%;
    left: 29%;
    z-index: 2;
  }

  .droparea:hover {
    border: 1px dashed #fdc7d7;
  }

  .home-droparea {
    position: absolute;
    top: 20%;
    left: 30%;
    z-index: 2;
  }
